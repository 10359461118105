import {
  Backdrop,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Icon,
  IconButton,
  InputBase,
  makeStyles,
  Tooltip,
} from "@material-ui/core";
import Button from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
// material-ui
import { withStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import EventIcon from "@material-ui/icons/Event";
import Place from "@material-ui/icons/Place";
import { Alert } from "@material-ui/lab";
import * as Sentry from "@sentry/react";
import logo from "assets/logo_main.png";
// assets
import logoDark from "assets/logo_main_dark.png";
import AnswerConfirmation from "components/AnswerConfirmation";
// reactor
import Page from "components/Page";
import Signature from "components/Signature";
import Table from "components/Table";
import {
  GOOGLE_MAPS_GEOCODING_KEY,
  REACT_APP_CLIENT_WEBSITE,
  REACT_APP_LOGO,
} from "config";
import GoogleMapReact from "google-map-react";
import { createIcs } from "helpers";
import moment from "moment-timezone";
import PropTypes from "prop-types";
import React, { Component } from "react";
import Confetti from "react-confetti";
import Div100vh from "react-div-100vh";
import { useDropzone } from "react-dropzone";
import darkGoogleMaps from "./darkGoogleMaps.json";
import headerMask from "./mask.svg";
// styles
import styles from "./styles";
import "./styles.css";

function Interactor({ interactor, setGrabber, onSubmit, highlight }) {
  const [sign, setSign] = React.useState(() => (x) => console.log("Hello"));
  const [fileCount, setFileCount] = React.useState(0);
  const [check, setCheck] = React.useState(false);
  const [text, setText] = React.useState("");
  const [signatureDone, setSignatureDone] = React.useState(false);
  const useStyles = makeStyles(styles);
  const classes = useStyles(styles);
  const { acceptedFiles, getRootProps, getInputProps } = useDropzone();

  const grabber = () => {
    switch (interactor.kind) {
      case "checkbox":
        return { interactor, value: check };
      case "text":
        return { interactor, value: text };
      case "signature":
        return { interactor, value: signatureDone && sign()() };
      case "file":
        return { interactor, value: acceptedFiles };
      default:
        break;
    }
  };

  React.useEffect(() => {
    setGrabber(grabber, interactor);
  });

  const files = acceptedFiles;

  switch (interactor.kind) {
    case "button":
      return (
        <Tooltip title={interactor.description}>
          <Button
            style={{
              margin: 4,
              flex: 1,
              color: "white",
              background: interactor.color,
              textTransform: "none",
            }}
            onClick={() => onSubmit(interactor)}
            variant="contained"
          >
            {interactor.icon ? (
              <i class={interactor.icon} style={{ paddingRight: 8 }}></i>
            ) : (
              []
            )}
            {interactor.title}
            {interactor.mandatory ? (
              <span style={{ fontSize: 10, opacity: 0.58 }}> - Required</span>
            ) : (
              []
            )}
          </Button>
        </Tooltip>
      );
    case "text":
      return (
        <div
          style={{
            flex: 1,
            background: highlight && "white",
            padding: 8,
            borderRadius: 8,
            zIndex: highlight && 1000,
            color: highlight && "black",
          }}
        >
          <Typography variant="body2" color="inherit">
            {interactor.icon ? (
              <i class={interactor.icon} style={{ paddingRight: 8 }}></i>
            ) : (
              []
            )}
            {interactor.title}
            {interactor.mandatory ? (
              <span style={{ fontSize: 10, opacity: 0.58 }}> - Required</span>
            ) : (
              []
            )}
          </Typography>
          <div
            className={classes.interactor}
            style={{
              flex: 1,
            }}
          >
            <InputBase
              style={{ flex: 1, fontSize: 16, color: "inherit" }}
              fullWidth
              variant="outlined"
              multiline
              rowsMax={5}
              value={text}
              onChange={(e) => setText(e.target.value)}
              label={
                <span>
                  {interactor.icon ? <i class={interactor.icon}></i> : []}{" "}
                  {interactor.title}
                </span>
              }
              size="small"
              placeholder={interactor.description}
            />
          </div>
        </div>
      );
    case "signature":
      return (
        <div
          style={{
            flex: 1,
            background: highlight && "white",
            padding: 8,
            borderRadius: 8,
            zIndex: highlight && 1000,
            color: highlight && "black",
          }}
        >
          <Typography variant="body2" color="inherit">
            {interactor.icon ? (
              <i class={interactor.icon} style={{ paddingRight: 8 }}></i>
            ) : (
              []
            )}
            {interactor.title}
            {interactor.mandatory ? (
              <span style={{ fontSize: 10, opacity: 0.58 }}> - Required</span>
            ) : (
              []
            )}
          </Typography>
          <div
            style={{
              flex: 1,
              padding: 0,
            }}
            className={classes.interactor}
          >
            <div
              style={{
                display: "flex",
                flex: 1,
                minHeight: 200,
                flexDirection: "column",
              }}
            >
              <Typography
                variant="caption"
                color="inherit"
                style={{ position: "absolute", padding: 6, opacity: 0.58 }}
              >
                {interactor.description}
              </Typography>
              <Signature
                clearSignature={() => {
                  setSignatureDone(false);
                }}
                signatureHasChanged={() => {
                  setSignatureDone(true);
                }}
                getSignature={(f) => {
                  setSign(() => () => f);
                }}
                color={interactor.color}
              />
            </div>
          </div>
        </div>
      );
    case "download":
      return (
        <div
          style={{
            flex: 1,
            background: highlight && "white",
            padding: 8,
            borderRadius: 8,
            zIndex: highlight && 1000,
            color: highlight && "black",
          }}
        >
          <Typography variant="body2" color="inherit">
            {interactor.icon ? (
              <i class={interactor.icon} style={{ paddingRight: 8 }}></i>
            ) : (
              []
            )}
            {interactor.title}
            {interactor.mandatory ? (
              <span style={{ fontSize: 10, opacity: 0.58 }}> - Required</span>
            ) : (
              []
            )}
          </Typography>
          <div
            className={classes.interactor}
            style={{
              flex: 1,
            }}
          >
            <div style={{ flex: 2 }}>
              <Typography
                variant="caption"
                color="inherit"
                style={{ opacity: 0.58 }}
              >
                {interactor.description}
              </Typography>
            </div>
            <div style={{ flex: 1, textAlign: "right" }}>
              <Button
                style={{ textTransform: "none" }}
                size="small"
                variant="outlined"
                onClick={() => window.open(interactor.data, "_blank")}
              >
                <i
                  style={{ paddingRight: 8 }}
                  class="fad fa-cloud-download-alt"
                ></i>{" "}
                Download
              </Button>
            </div>
          </div>
        </div>
      );

    case "checkbox":
      return (
        <div
          style={{
            flex: 1,
            background: highlight && "white",
            padding: 8,
            borderRadius: 8,
            zIndex: highlight && 1000,
            color: highlight && "black",
          }}
        >
          <Typography variant="body2" color="inherit">
            {interactor.icon ? (
              <i class={interactor.icon} style={{ paddingRight: 8 }}></i>
            ) : (
              []
            )}
            {interactor.title}
            {interactor.mandatory ? (
              <span style={{ fontSize: 10, opacity: 0.58 }}> - Required</span>
            ) : (
              []
            )}
          </Typography>
          <div
            className={classes.interactor}
            style={{
              flex: 1,
            }}
          >
            <div style={{ flex: 2 }}>
              <Typography
                variant="caption"
                color="inherit"
                style={{ opacity: 0.58 }}
              >
                {interactor.description}
              </Typography>
            </div>
            <div style={{ flex: 1, textAlign: "right" }}>
              <Checkbox
                style={{ color: interactor.color || "inherit" }}
                checked={check}
                onChange={(e) => setCheck(e.target.checked)}
              />
            </div>
          </div>
        </div>
      );
    case "file":
      return (
        <div
          style={{
            flex: 1,
            background: highlight && "white",
            padding: 8,
            borderRadius: 8,
            zIndex: highlight && 1000,
            color: highlight && "black",
          }}
        >
          <Typography variant="body2" color="inherit">
            {interactor.icon ? (
              <i class={interactor.icon} style={{ paddingRight: 8 }}></i>
            ) : (
              []
            )}
            {interactor.title}
            {interactor.mandatory ? (
              <span style={{ fontSize: 10, opacity: 0.58 }}> - Required</span>
            ) : (
              []
            )}
          </Typography>
          <div
            className={classes.interactor}
            style={{
              flex: 1,
              display: "flex",
              flexDirection: "column",
              alignItems: "stretch",
            }}
          >
            {files.map((f) => (
              <div
                style={{
                  display: "flex",
                  flex: 1,
                  alignItems: "center",
                  justifyContent: "space-between",
                  borderBottom: "solid 1px rgba(155,155,155,0.3)",
                }}
              >
                <div style={{ display: "flex", alignItems: "center" }}>
                  <Typography variant="h4" color="inherit">
                    <i class="fad fa-file-alt"></i>
                  </Typography>
                  <div style={{ paddingLeft: 20 }}>
                    <div style={{ display: "flex" }}>
                      <Typography variant="caption" color="inherit">
                        {f.name}
                      </Typography>
                    </div>
                    <Typography
                      variant="caption"
                      color="inherit"
                      style={{ fontSize: 10, opacity: 0.58 }}
                    >
                      {f.type}
                    </Typography>
                  </div>
                </div>
                <IconButton
                  size="small"
                  style={{ marginLeft: 20 }}
                  onClick={() => {
                    const newFiles = [...files];
                    acceptedFiles.splice(0, 1);
                    setFileCount(fileCount + 1);
                  }}
                >
                  <Icon fontSize="inherit" className="far fa-times-circle" />
                </IconButton>
              </div>
            ))}
            <div
              {...getRootProps({ className: "dropzone" })}
              style={{
                display: "flex",
                flex: 1,
                cursor: "pointer",
                outlineWidth: 0,
              }}
            >
              <div
                style={{
                  display: "flex",
                  flex: 1,
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <Typography
                  variant="h4"
                  color="inherit"
                  style={{ opacity: 0.58 }}
                >
                  <i class="fad fa-cloud-upload"></i>
                </Typography>
                <div style={{ paddingLeft: 20 }}>
                  <div style={{ display: "flex" }}>
                    <Typography
                      variant="caption"
                      color="inherit"
                      style={{ opacity: 0.58 }}
                    >
                      {interactor.description}
                    </Typography>
                  </div>
                  <input {...getInputProps()} />
                  <Typography
                    variant="caption"
                    color="inherit"
                    style={{ fontSize: 10, opacity: 0.58 }}
                  >
                    Drag and drop here or browse files
                  </Typography>
                </div>
              </div>
            </div>
          </div>
        </div>
      );
    default:
      break;
  }
  return <Alert severity="error">Unknown interactor</Alert>;
}

class App extends Component {
  static propTypes = {
    classes: PropTypes.object,
    darkMode: PropTypes.bool,
    invite: PropTypes.object,
    loading: PropTypes.bool,
    notFound: PropTypes.bool,
    preview: PropTypes.bool,
    greeting: PropTypes.string,
    projectID: PropTypes.string,
    notes: PropTypes.string,
    createTracker: PropTypes.func,
    upload: PropTypes.func,
    updateJob: PropTypes.func,
    updateProjectMercury: PropTypes.func,
    token: PropTypes.string,
    answer: PropTypes.string,
    hideCalendar: PropTypes.string,
    acknowledgeStudioSessionInvite: PropTypes.func,
  };

  static contextTypes = {
    NotificationCenter: PropTypes.object,
  };

  interactorGrabbers = {};

  constructor(...args) {
    super(...args);
    const { answer } = this.props;
    this.state = {
      requestLoading: false,
      confirmMessageOpen: answer,
      emailPending: false,
      confetti: false,
    };
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.invite && this.props.invite === undefined) {
      const { invite } = nextProps;

      if (invite.expired) {
        this.setState({
          remaining: "Invitation Expired",
        });
      }

      if (nextProps.invite && !this.props.invite) {
        if (nextProps.urlParams.interactorID) {
          this.setState({
            selectedInteractor: nextProps.invite.interactors.find(
              (i) => i.id === Number(nextProps.urlParams.interactorID)
            ),
          });
        }
      }
    }
  }

  handleMusicianAnswer(a) {
    this.setState({
      confirmMessageOpen: true,
      action: a,
    });
  }

  async refresh() {
    const { token } = this.state;
    const { getStudioSessionInvites } = this.props;

    const resp = await getStudioSessionInvites(token);
    this.setState({ invite: resp.payload, loading: false });
  }

  async acknowledgeStudioSessionInvite(selectedStatusID, notes) {
    const {
      acknowledgeStudioSessionInvite,
      invite,
      token,
      refresh,
    } = this.props;
    const { NotificationCenter } = this.context;
    this.setState({ requestLoading: true, confirmMessageOpen: false });
    await acknowledgeStudioSessionInvite(invite.sessionID, {
      musicianToken: token,
      notes,
      statusID: selectedStatusID,
    });

    let level = "success";
    if (selectedStatusID === 3) level = "info";
    if (selectedStatusID === 2) level = "error";
    NotificationCenter.stack(
      {
        title: "Thank You!",
        subtitle:
          "We received your answer. You will receive a confirmation email shortly.",
        timestamp: new Date().getTime(),
        info: selectedStatusID === 3,
        success: selectedStatusID === 1,
        error: selectedStatusID === 2,
      },
      {
        confirm: {
          label: "Ok",
          level,
        },
      }
    );
    setTimeout(refresh, 2000);
    this.setState({ requestLoading: false, emailPending: true });
  }

  addToCalendar() {
    const { invite } = this.props;

    try {
      const blob = new Blob([createIcs(invite)], {
        type: "text/calendar",
      });
      const a = document.createElement("a");
      document.body.appendChild(a);
      const url = window.URL.createObjectURL(blob);
      a.href = url;
      a.download =
        `${invite.projectName} by ${invite.contractor}` ||
        `Untitled Session by ${invite.contractor}`;
      a.click();
      setTimeout(() => {
        window.URL.revokeObjectURL(url);
        document.body.removeChild(a);
      }, 0);
    } catch (error) {
      Sentry.captureException(error);
      alert(error);
      return;
    }
  }

  async onSubmit(interactor) {
    const {
      invite,
      upload,
      updateJob,
      updateProjectMercury,
      refresh,
      projectID,
    } = this.props;
    const ErrorJSX = [];
    // grab all interactor values
    const body = {
      mercuryInteractorID: interactor.id,
      mercuryInspectors: [],
      sessionID: invite.sessionID,
      publicToken: invite.token,
    };

    for (const key in this.interactorGrabbers) {
      if (Object.hasOwnProperty.call(this.interactorGrabbers, key)) {
        const grabber = this.interactorGrabbers[key]();
        if (grabber?.interactor?.mandatory && !grabber?.value) {
          ErrorJSX.push(
            <Typography>
              {grabber.interactor.icon ? (
                <i
                  class={grabber.interactor.icon}
                  style={{ paddingRight: 8 }}
                ></i>
              ) : (
                []
              )}
              {`${grabber.interactor.title} is required`}
            </Typography>
          );
        }
      }
    }

    if (ErrorJSX.length) {
      this.setState({ ErrorJSX });
    } else {
      // ready to answer!
      this.setState({ backdrop: true, selectedInteractor: undefined });
      for (const key in this.interactorGrabbers) {
        if (Object.hasOwnProperty.call(this.interactorGrabbers, key)) {
          const grabber = this.interactorGrabbers[key]();
          if (grabber?.value) {
            const i = grabber.interactor;
            let v = grabber.value;
            if (i.kind === "file") {
              for (const key in v) {
                if (Object.hasOwnProperty.call(v, key)) {
                  const file = v[key];
                  const r = await upload(file);
                  if (r.success) {
                    body.mercuryInspectors.push({
                      title: file.name,
                      message: `${r.payload}`,
                      icon: i.icon,
                      kind: i.kind,
                    });
                  }
                }
              }
            } else if (i.kind === "signature") {
              const f = await fetch(v);
              const blob = await f.blob();
              const r = await upload(blob);
              if (r.success) {
                body.mercuryInspectors.push({
                  title: i.title,
                  message: `${r.payload}`,
                  icon: i.icon,
                  kind: i.kind,
                });
              }
            } else {
              body.mercuryInspectors.push({
                title: i.title,
                message: `${v}`,
                icon: i.icon,
                kind: i.kind,
              });
            }
          }
        }
      }

      let r;
      if (projectID || invite.projectID > 0) {
        body.projectID = Number(projectID ? projectID : invite.projectID);
        r = await updateProjectMercury(body);
      } else {
        r = await updateJob(body);
      }

      await refresh();

      if (r?.success) {
        this.setState({
          backdrop: false,
          confetti: interactor.id === 1 || interactor.id === 5,
          answered: true,
        });
      } else {
        this.setState({ backdrop: false });
      }
    }
  }

  async updateSMSpreference(contactBySMS) {
    const { invite, updateJob } = this.props;
    await updateJob({
      musician: { contactBySMS },
      sessionID: invite.sessionID,
      publicToken: invite.token,
    });
  }

  render() {
    const {
      classes,
      invite,
      loading,
      notFound,
      darkMode,
      notes,
      projectID,
    } = this.props;

    const {
      requestLoading,
      selectedInteractor,
      confetti,
      answered,
    } = this.state;

    const sortedInteractors = invite?.interactors?.sort(
      (a, b) => a.segueID || 0 - b.segueID || 0
    );

    if (notFound) {
      return (
        <Page helmet="Invite not found" noPadding>
          <Div100vh>
            <Grid
              container
              alignItems="center"
              justify="center"
              className={classes.notFound}
              style={{ height: "100%" }}
            >
              <Grid item style={{ textAlign: "center" }}>
                <Typography display="block" variant="h1">
                  <span role="img" aria-label="duno">
                    💁🏻‍♂️
                  </span>
                </Typography>
                <Typography
                  display="block"
                  variant="h2"
                  style={{ fontWeight: 600 }}
                >
                  Oooops !
                </Typography>
                <Typography display="block" variant="h6">
                  Invitation not found.
                </Typography>
                <br />
                <Typography display="block">
                  The intern must have lost it again !!!
                </Typography>
                <Typography display="block">
                  Contact us at{" "}
                  <a
                    style={{ color: "#ffa000", textDecoration: "none" }}
                    href="mailTo:support@rhapsody.la"
                  >
                    support@rhapsody.la
                  </a>{" "}
                  if you think it is a bug.
                </Typography>
              </Grid>
            </Grid>
            <a
              style={{
                position: "absolute",
                bottom: 20,
                textAlign: "center",
                width: "100%",
              }}
              href="https://www.rhapsody.la"
            >
              <img
                src={REACT_APP_LOGO}
                alt="rhapsody"
                style={{
                  width: 100,
                }}
              />
            </a>
          </Div100vh>
        </Page>
      );
    }

    if (invite && invite.expired) {
      return (
        <Page helmet="Invite Expired" noPadding>
          <Div100vh>
            <Grid
              container
              alignItems="center"
              justify="center"
              className={classes.notFound}
              style={{ height: "100%" }}
            >
              <Grid item style={{ textAlign: "center" }}>
                {invite.contractorLogo && invite.contractorLogo.length ? (
                  <img
                    src={invite.contractorLogo}
                    alt="customer-logo"
                    style={{
                      width: "calc(100% - 40px)",
                      maxWidth: 350,
                      cursor: "pointer",
                      marginBottom: 20,
                      background: "white",
                      padding: 20,
                      borderRadius: 4,
                    }}
                  />
                ) : (
                  []
                )}
                <Typography display="block" variant="h6">
                  We found the invitation but unfortunately, this one has
                  expired.
                </Typography>
                <Typography
                  display="block"
                  variant="caption"
                  style={{ marginTop: 8 }}
                >
                  If you have any questions, please contact {invite.contractor}.
                  Reply to the email or{" "}
                  <a
                    style={{ color: "#ffa000", textDecoration: "none" }}
                    href={`tel:${invite.companyPhone}`}
                  >
                    {invite.companyPhone}
                  </a>
                </Typography>
              </Grid>
            </Grid>
            <a
              style={{
                position: "absolute",
                bottom: 20,
                textAlign: "center",
                width: "100%",
              }}
              href="https://www.rhapsody.la"
            >
              <img
                src={darkMode ? logoDark : logo}
                alt="rhapsody"
                style={{
                  width: 100,
                }}
              />
            </a>
          </Div100vh>
        </Page>
      );
    }

    return (
      <Page
        helmet={invite?.currentStage.name || "Loading"}
        loadingMessage={"Loading Invite"}
        loading={loading}
        darkMode={darkMode}
        noPadding
      >
        {invite ? (
          <div style={{ minHeight: "100vh" }}>
            <Grid
              container
              alignItems="center"
              justify="center"
              className={classes.container}
              direction="column"
            >
              <Grid item>
                <Paper className={classes.paper} elevation={0}>
                  {invite.headerURL ? (
                    <div
                      style={{
                        WebkitMaskImage: `url(${headerMask})`,
                        maskImage: `url(${headerMask})`,
                      }}
                    >
                      <img
                        src={invite.headerURL}
                        alt="header"
                        style={{
                          width: "106.3%",
                          marginLeft: "-3.1%",
                          marginTop: "-1.8%",
                        }}
                      />
                    </div>
                  ) : (
                    <a href="https://www.rhapsody.la">
                      <img
                        src={invite.headerURL}
                        alt="header"
                        style={{
                          width: "100%",
                        }}
                      />
                    </a>
                  )}
                  <div style={{ padding: 8 }}>
                    <Grid container spacing={2} style={{ padding: 8 }}>
                      <Grid item xs={12} style={{ textAlign: "center" }}>
                        {invite.contractorLogo ? (
                          <img
                            src={invite.contractorLogo}
                            alt="customer-logo"
                            style={{
                              width: "100%",
                              background: "white",
                              maxWidth: 250,
                              cursor: "pointer",
                              marginBottom: 16,
                              padding: 20,
                              borderRadius: 4,
                            }}
                          />
                        ) : (
                          []
                        )}
                        <Typography
                          display="block"
                          variant="h1"
                          style={{
                            color: invite?.currentStage.color,
                          }}
                          classes={{
                            h1: classes.h1,
                          }}
                        >
                          {invite?.currentStage.icon ? (
                            <img
                              alt="stage"
                              src={invite?.currentStage.icon}
                              style={{ height: 18 }}
                            />
                          ) : (
                            []
                          )}{" "}
                          {invite?.currentStage.name}
                        </Typography>
                        <Typography
                          display="block"
                          variant="h1"
                          classes={{
                            h1: classes.h1,
                          }}
                          style={{
                            marginBottom: 16,
                            marginTop: 16,
                          }}
                        >
                          <b>{invite.title}</b>
                        </Typography>
                        <Typography
                          display="block"
                          variant="body"
                          classes={{
                            body: classes.body,
                          }}
                          style={{
                            marginBottom: 16,
                          }}
                        >
                          Hi,
                          <span className={classes.primaryColor}>
                            <b>{` ${invite.musicianName}, `}</b>
                          </span>
                          <br />
                          {invite.greeting}
                        </Typography>
                        <div className={classes.position}>
                          <Typography
                            variant="subtitle"
                            classes={{
                              subtitle: classes.subtitle,
                            }}
                          >
                            <i class="fad fa-chair"></i> {invite.role} -{" "}
                            {invite.section}
                          </Typography>
                        </div>
                      </Grid>
                      {invite.memo ? (
                        <Grid item xs={6} sm={4}>
                          <Typography
                            display="block"
                            variant="body"
                            classes={{
                              body: classes.body,
                            }}
                          >
                            Memo
                          </Typography>
                          <Typography
                            display="block"
                            variant="body"
                            classes={{
                              body: classes.body,
                            }}
                            className={classes.primaryColor}
                          >
                            {invite.memo}
                          </Typography>
                        </Grid>
                      ) : (
                        []
                      )}
                      {invite.musicianTalkBack ? (
                        <Grid item xs={12} style={{ textAlign: "center" }}>
                          <div
                            style={{
                              background: invite.currentStage?.color,
                              color: "white",
                              padding: "4px 8px",
                              borderRadius: 8,
                              fontFamily: "poppins",
                              fontSize: 12,
                              display: "inline",
                            }}
                          >
                            {notes}
                          </div>
                        </Grid>
                      ) : (
                        []
                      )}
                      <Grid item xs={12}>
                        {!answered ? (
                          <div
                            style={{
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "center",
                              flexWrap: "wrap",
                            }}
                          >
                            {sortedInteractors.map((i, k) => {
                              const JSX = [];
                              if (
                                Number(k) > 0 &&
                                Number(k) <= sortedInteractors.length - 1
                              ) {
                                const prev = sortedInteractors[Number(k - 1)];
                                if (!prev.segueID) {
                                  JSX.push(
                                    <div
                                      style={{ height: 10, flexBasis: "100%" }}
                                    />
                                  );
                                }
                              }
                              JSX.push(
                                <Interactor
                                  handleMusicianAnswer={
                                    this.handleMusicianAnswer
                                  }
                                  highlight={selectedInteractor}
                                  onSubmit={(s) =>
                                    this.setState({ selectedInteractor: s })
                                  }
                                  setGrabber={(grabberFunc, interactor) => {
                                    this.interactorGrabbers[
                                      interactor.id
                                    ] = grabberFunc;
                                  }}
                                  interactor={i}
                                />
                              );
                              return JSX;
                            })}
                          </div>
                        ) : (
                          <Alert severity="success">
                            Thank you, we got your answer.
                          </Alert>
                        )}
                      </Grid>
                      {requestLoading ? (
                        <Grid item style={{ textAlign: "center" }} xs={12}>
                          <CircularProgress />
                        </Grid>
                      ) : (
                        []
                      )}
                      <Grid item>
                        <Divider style={{ magin: "30px 0px" }} />
                      </Grid>
                      {invite.rehearsals && invite.rehearsals.length ? (
                        <Grid item xs={12}>
                          <div className={classes.card}>
                            <Typography
                              display="block"
                              variant="body"
                              classes={{
                                body: classes.body,
                              }}
                              style={{ paddingLeft: 4 }}
                            >
                              REHEARSAL{invite.rehearsals.length > 1 ? "S" : ""}
                            </Typography>
                            <Table
                              data={invite.rehearsals}
                              noHeader
                              noPaper
                              meta={[
                                {
                                  path: "formatedDate",
                                  numeric: false,
                                  render: (t, d) => (
                                    <Grid container justify="space-between">
                                      <Grid item>
                                        <Typography
                                          display="block"
                                          variant="body"
                                          classes={{
                                            body: classes.body,
                                          }}
                                          className={classes.primaryColor}
                                        >
                                          {d.noTimeRange
                                            ? `${d.key}. ${moment
                                                .tz(d.dateFromUTC, d.tzName)
                                                .format("LL")}`
                                            : `${d.key}. ${moment
                                                .tz(d.dateFromUTC, d.tzName)
                                                .format("LLL")} - ${moment
                                                .tz(d.dateToUTC, d.tzName)
                                                .format("h:mm A")}`}
                                        </Typography>
                                        {d.venue && d.venue.name ? (
                                          <React.Fragment>
                                            <Typography
                                              display="block"
                                              variant="caption"
                                              classes={{
                                                caption: classes.caption,
                                              }}
                                              className={classes.primaryColor}
                                            >
                                              @{d.venue.name}
                                            </Typography>
                                            <Typography
                                              display="block"
                                              variant="caption"
                                              classes={{
                                                caption: classes.caption,
                                              }}
                                              className={classes.primaryColor}
                                            >
                                              {d.venue.address
                                                ? ` ${d.venue.address}`
                                                : ""}
                                              {d.venue.zipcode
                                                ? `, ${d.venue.zipcode}`
                                                : ""}
                                              {d.venue.city
                                                ? ` ${d.venue.city}`
                                                : ""}
                                              {d.venue.state
                                                ? ` ${d.venue.state}`
                                                : ""}
                                            </Typography>
                                          </React.Fragment>
                                        ) : (
                                          []
                                        )}
                                      </Grid>
                                    </Grid>
                                  ),
                                },
                              ]}
                            />
                          </div>
                        </Grid>
                      ) : (
                        []
                      )}
                      {invite.performances && invite.performances.length ? (
                        <Grid item xs={12}>
                          <div className={classes.card}>
                            <Typography
                              display="block"
                              variant="body"
                              classes={{
                                body: classes.body,
                              }}
                              style={{ paddingLeft: 4 }}
                            >
                              PERFORMANCE
                              {invite.performances.length > 1 ? "S" : ""}
                            </Typography>
                            <Table
                              data={invite.performances}
                              noHeader
                              noPaper
                              meta={[
                                {
                                  path: "formatedDate",
                                  numeric: false,
                                  render: (t, d) => (
                                    <Grid container justify="space-between">
                                      <Grid item>
                                        <Typography
                                          display="block"
                                          variant="body"
                                          classes={{
                                            body: classes.body,
                                          }}
                                          className={classes.primaryColor}
                                        >
                                          {d.noTimeRange
                                            ? `${d.key}. ${moment
                                                .tz(d.dateFromUTC, d.tzName)
                                                .format("LL")}`
                                            : `${d.key}. ${moment
                                                .tz(d.dateFromUTC, d.tzName)
                                                .format("LLL")} - ${moment
                                                .tz(d.dateToUTC, d.tzName)
                                                .format("h:mm A")}`}
                                        </Typography>
                                        {d.venue && d.venue.name ? (
                                          <React.Fragment>
                                            <Typography
                                              display="block"
                                              variant="caption"
                                              classes={{
                                                caption: classes.caption,
                                              }}
                                              className={classes.primaryColor}
                                            >
                                              @{d.venue.name}
                                            </Typography>
                                            <Typography
                                              display="block"
                                              variant="caption"
                                              classes={{
                                                caption: classes.caption,
                                              }}
                                              className={classes.primaryColor}
                                            >
                                              {d.venue.address
                                                ? ` ${d.venue.address}`
                                                : ""}
                                              {d.venue.zipcode
                                                ? `, ${d.venue.zipcode}`
                                                : ""}
                                              {d.venue.city
                                                ? ` ${d.venue.city}`
                                                : ""}
                                              {d.venue.state
                                                ? ` ${d.venue.state}`
                                                : ""}
                                            </Typography>
                                          </React.Fragment>
                                        ) : (
                                          []
                                        )}
                                      </Grid>
                                    </Grid>
                                  ),
                                },
                              ]}
                            />
                          </div>
                        </Grid>
                      ) : (
                        []
                      )}
                      {!invite.live &&
                      invite.recordings &&
                      invite.recordings.length ? (
                        <Grid item xs={12}>
                          <div className={classes.card}>
                            <Typography
                              display="block"
                              variant="body"
                              classes={{
                                body: classes.body,
                              }}
                              style={{ paddingLeft: 4 }}
                            >
                              SERVICES
                            </Typography>
                            <Table
                              data={invite.recordings}
                              noHeader
                              noPaper
                              meta={[
                                {
                                  path: "formatedDate",
                                  numeric: false,
                                  render: (t, d) => (
                                    <Grid container justify="space-between">
                                      <Grid item>
                                        <Typography
                                          display="block"
                                          variant="body"
                                          classes={{
                                            body: classes.body,
                                          }}
                                          className={classes.primaryColor}
                                        >
                                          {d.noTimeRange
                                            ? `${d.key}. ${moment
                                                .tz(d.dateFromUTC, d.tzName)
                                                .format("LL")}`
                                            : `${d.key}. ${moment
                                                .tz(d.dateFromUTC, d.tzName)
                                                .format("LLL")} - ${moment
                                                .tz(d.dateToUTC, d.tzName)
                                                .format("h:mm A")}`}
                                        </Typography>
                                        {d.venue && d.venue.name ? (
                                          <React.Fragment>
                                            <Typography
                                              display="block"
                                              variant="caption"
                                              classes={{
                                                caption: classes.caption,
                                              }}
                                              className={classes.primaryColor}
                                            >
                                              @{d.venue.name}
                                            </Typography>
                                            <Typography
                                              display="block"
                                              variant="caption"
                                              classes={{
                                                caption: classes.caption,
                                              }}
                                              className={classes.primaryColor}
                                            >
                                              {d.venue.address
                                                ? ` ${d.venue.address}`
                                                : ""}
                                              {d.venue.zipcode
                                                ? `, ${d.venue.zipcode}`
                                                : ""}
                                              {d.venue.city
                                                ? ` ${d.venue.city}`
                                                : ""}
                                              {d.venue.state
                                                ? ` ${d.venue.state}`
                                                : ""}
                                            </Typography>
                                          </React.Fragment>
                                        ) : (
                                          []
                                        )}
                                      </Grid>
                                      {projectID ? (
                                        <Grid item>
                                          <Tooltip title="Open in Google Maps">
                                            <img
                                              onClick={() =>
                                                window.open(
                                                  `https://maps.google.com/?q=${d.venue.latitude},${d.venue.longitude}`
                                                )
                                              }
                                              alt="google-maps"
                                              style={{
                                                width: 50,
                                                height: 50,
                                                border:
                                                  "solid 1px rgba(155,155,155,0.3)",
                                                borderRadius: 4,
                                              }}
                                              src={`https://maps.googleapis.com/maps/api/staticmap?center=${d.venue.latitude},${d.venue.longitude}&zoom=15&size=200x230&maptype=roadmap&markers=color:red%7C${d.venue.latitude},${d.venue.longitude}&key=${GOOGLE_MAPS_GEOCODING_KEY}`}
                                            />
                                          </Tooltip>
                                        </Grid>
                                      ) : (
                                        []
                                      )}
                                    </Grid>
                                  ),
                                },
                              ]}
                            />
                          </div>
                        </Grid>
                      ) : (
                        []
                      )}
                      <Grid item xs={12} style={{ textAlign: "center" }}>
                        <Button
                          style={{
                            textTransform: "none",
                            background: darkMode ? "white" : "black",
                            color: darkMode ? "black" : "white",
                          }}
                          onClick={this.addToCalendar.bind(this)}
                          variant="contained"
                        >
                          <EventIcon style={{ marginRight: 10 }} />
                          Add to Calendar
                        </Button>
                      </Grid>
                      {!projectID &&
                      invite.studio &&
                      invite.studio.latitude !== undefined &&
                      invite.studio.longitude !== undefined ? (
                        <Grid item xs={12}>
                          {invite && invite.studio?.id ? (
                            <div>
                              <Typography
                                display="block"
                                variant="body"
                                classes={{
                                  body: classes.body,
                                }}
                              >
                                Your presence is expected at this address :
                              </Typography>
                              <Typography
                                display="block"
                                variant="body"
                                classes={{
                                  body: classes.body,
                                }}
                                className={classes.primaryColor}
                              >
                                {invite.studio.name}
                              </Typography>
                              <Typography
                                display="block"
                                variant="body"
                                classes={{
                                  body: classes.body,
                                }}
                                className={classes.primaryColor}
                              >
                                {invite.studio.address}
                              </Typography>
                              <Typography
                                display="block"
                                variant="body"
                                classes={{
                                  body: classes.body,
                                }}
                                className={classes.primaryColor}
                              >
                                {invite.studio.zipcode} {invite.studio.city}{" "}
                                {invite.studio.state}
                              </Typography>
                            </div>
                          ) : (
                            []
                          )}
                          {invite.studio.latitude !== undefined &&
                          invite.studio.latitude ? (
                            <div
                              style={{
                                height: 300,
                                borderRadius: 6,
                                overflow: "hidden",
                              }}
                            >
                              <GoogleMapReact
                                bootstrapURLKeys={{
                                  key:
                                    "AIzaSyD67kHvhZC_mBkppkJIMAkZDu4c279J_As",
                                }}
                                options={
                                  darkMode
                                    ? { styles: darkGoogleMaps }
                                    : undefined
                                }
                                center={{
                                  lat: parseFloat(invite.studio.latitude),
                                  lng: parseFloat(invite.studio.longitude),
                                }}
                                defaultZoom={14}
                              >
                                <Place
                                  lat={parseFloat(invite.studio.latitude)}
                                  lng={parseFloat(invite.studio.longitude)}
                                  text={"Here"}
                                  style={{
                                    color: "#f44336",
                                    marginTop: -45,
                                    marginLeft: -17,
                                  }}
                                  fontSize="large"
                                />
                              </GoogleMapReact>
                            </div>
                          ) : (
                            []
                          )}
                        </Grid>
                      ) : (
                        []
                      )}
                      {invite.projectName ? (
                        <Grid item xs={6} sm={4}>
                          <Typography
                            display="block"
                            variant="body"
                            classes={{
                              body: classes.body,
                            }}
                          >
                            PROJECT
                          </Typography>
                          <Typography
                            display="block"
                            variant="body"
                            classes={{
                              body: classes.body,
                            }}
                            className={classes.primaryColor}
                          >
                            {invite.projectName}
                          </Typography>
                        </Grid>
                      ) : (
                        []
                      )}
                      {!projectID && invite.studio ? (
                        <Grid item xs={6} sm={4}>
                          <Typography
                            display="block"
                            variant="body"
                            classes={{
                              body: classes.body,
                            }}
                          >
                            LOCATION
                          </Typography>
                          <Typography
                            display="block"
                            variant="body"
                            classes={{
                              body: classes.body,
                            }}
                            className={classes.primaryColor}
                          >
                            {invite.studio && invite.studio.name
                              ? invite.studio.name
                              : "Unknown Location"}
                          </Typography>
                        </Grid>
                      ) : (
                        []
                      )}
                      {invite.composer ? (
                        <Grid item xs={6} sm={4}>
                          <Typography
                            display="block"
                            variant="body"
                            classes={{
                              body: classes.body,
                            }}
                          >
                            COMPOSER
                          </Typography>
                          <Typography
                            display="block"
                            variant="body"
                            classes={{
                              body: classes.body,
                            }}
                            className={classes.primaryColor}
                          >
                            {invite.composer}
                          </Typography>
                        </Grid>
                      ) : (
                        []
                      )}
                      {invite.leader ? (
                        <Grid item xs={6} sm={4}>
                          <Typography
                            display="block"
                            variant="body"
                            classes={{
                              body: classes.body,
                            }}
                          >
                            LEADER
                          </Typography>
                          <Typography
                            display="block"
                            variant="body"
                            classes={{
                              body: classes.body,
                            }}
                            className={classes.primaryColor}
                          >
                            {invite.leader}
                          </Typography>
                        </Grid>
                      ) : (
                        []
                      )}
                      {invite.scale ? (
                        <Grid item xs={6} sm={4}>
                          <Typography
                            display="block"
                            variant="body"
                            classes={{
                              body: classes.body,
                            }}
                          >
                            WAGE SCALE
                          </Typography>
                          <Typography
                            display="block"
                            variant="body"
                            classes={{
                              body: classes.body,
                            }}
                            className={classes.primaryColor}
                          >
                            {invite.scale}
                          </Typography>
                        </Grid>
                      ) : (
                        []
                      )}
                      {invite.payroll ? (
                        <Grid item xs={6} sm={4}>
                          <Typography
                            display="block"
                            variant="body"
                            classes={{
                              body: classes.body,
                            }}
                          >
                            PAYROLL
                          </Typography>
                          <Typography
                            display="block"
                            variant="body"
                            classes={{
                              body: classes.body,
                            }}
                            className={classes.primaryColor}
                          >
                            {invite.payroll}
                          </Typography>
                        </Grid>
                      ) : (
                        []
                      )}
                      {invite.contractor ? (
                        <Grid item xs={6} sm={4}>
                          <Typography
                            display="block"
                            variant="body"
                            classes={{
                              body: classes.body,
                            }}
                          >
                            CONTRACTOR
                          </Typography>
                          <Typography
                            display="block"
                            variant="body"
                            classes={{
                              body: classes.body,
                            }}
                            className={classes.primaryColor}
                          >
                            {invite.contractor}
                          </Typography>
                        </Grid>
                      ) : (
                        []
                      )}
                      {invite.links && invite.links.length ? (
                        <Grid item xs={12}>
                          <div className={classes.card}>
                            <Typography
                              display="block"
                              variant="body"
                              classes={{
                                body: classes.body,
                              }}
                              style={{ paddingLeft: 4 }}
                            >
                              LINK{invite.links.length > 1 ? "S" : ""}
                            </Typography>
                            <Table
                              data={invite.links}
                              onRowSelect={(l) =>
                                window.open(
                                  l.url.indexOf("http") === -1
                                    ? `https://${l.url}`
                                    : l.url,
                                  "_blank"
                                )
                              }
                              noHeader
                              noPaper
                              meta={[
                                {
                                  path: "name",
                                  title: "LINKS",
                                  numeric: false,
                                  render: (t, d) => (
                                    <div>
                                      <Typography
                                        display="block"
                                        variant="body"
                                        classes={{
                                          body: classes.body,
                                        }}
                                        className={classes.primaryColor}
                                      >
                                        {`${d.name}${
                                          d.description
                                            ? ` ${d.description}`
                                            : ""
                                        }`}
                                      </Typography>
                                    </div>
                                  ),
                                },
                              ]}
                            />
                          </div>
                        </Grid>
                      ) : (
                        []
                      )}
                      {invite.expired && !invite.active ? (
                        <Grid item style={{ textAlign: "center" }} xs={12}>
                          <Button
                            style={{ marginTop: 20 }}
                            disabled
                            variant="outlined"
                            color="default"
                          >
                            Invitation Expired
                          </Button>
                        </Grid>
                      ) : (
                        []
                      )}
                      {invite.notes ? (
                        <Grid item xs={12}>
                          <div
                            style={{
                              border: "solid 1px rgba(155,155,155,0.3)",
                              padding: 4,
                            }}
                          >
                            <Typography
                              display="block"
                              variant="body"
                              classes={{
                                body: classes.body,
                              }}
                            >
                              Notes from {invite.contractor}
                            </Typography>
                            <Typography
                              display="block"
                              variant="body"
                              classes={{
                                body: classes.body,
                              }}
                              className={classes.primaryColor}
                            >
                              <div
                                dangerouslySetInnerHTML={{
                                  __html: invite.notes,
                                }}
                              />
                            </Typography>
                          </div>
                        </Grid>
                      ) : (
                        []
                      )}
                      <Grid item style={{ textAlign: "center" }} xs={12}>
                        <Typography
                          display="block"
                          variant="body"
                          classes={{
                            body: classes.body,
                          }}
                        >
                          If you have any questions, please contact{" "}
                          <span className={classes.primaryColor}>
                            {invite.contractor || "The Contractor"}.
                          </span>
                          <br />
                          Reply to the email or{" "}
                          <a
                            className={classes.primaryColor}
                            href={`tel:${invite.companyPhone}`}
                          >
                            {invite.companyPhone}
                          </a>
                        </Typography>
                      </Grid>
                    </Grid>
                  </div>
                </Paper>
              </Grid>
              <Grid
                item
                style={{
                  maxWidth: 600,
                  width: "100%",
                  marginTop: 20,
                  textAlign: "center",
                }}
                xs={12}
              >
                <div className={classes.sms}>
                  <Checkbox
                    onChange={(e) => this.updateSMSpreference(e.target.checked)}
                    style={{ color: "#32325d" }}
                    defaultChecked={invite.contactBySMS}
                  />
                  <div style={{ paddingRight: 20, textAlign: "left" }}>
                    <Typography
                      variant="body2"
                      display="block"
                      className={classes.primaryColor}
                    >
                      SMS Preferences
                    </Typography>
                    <Typography
                      variant="caption"
                      className={classes.secondaryColor}
                    >
                      I wish to receive Rhapsody updates via SMS
                    </Typography>
                  </div>
                  <Typography variant="h4" className={classes.primaryColor}>
                    <i class="fad fa-sms"></i>
                  </Typography>
                </div>
                <br />
                <a
                  href="https://storage.googleapis.com/rhapsody/Rhapsody.vcf"
                  style={{
                    background: "#272839",
                    padding: 6,
                    color: "white",
                    fontSize: 14,
                    borderRadius: 4,
                    display: "inline-block",
                    marginBottom: 16,
                    textDecoration: "none",
                  }}
                >
                  <i class="fad fa-address-card"></i> Add Rhapsody to your
                  Contacts
                </a>
                <Typography
                  display="block"
                  variant="caption"
                  classes={{
                    caption: classes.caption,
                  }}
                >
                  <a
                    href={`https://${REACT_APP_CLIENT_WEBSITE}`}
                    target="_blank"
                    rel="noopener noreferrer"
                    className={classes.primaryColor}
                  >
                    Rhapsody
                  </a>{" "}
                  a Robust and Purpose-Built Contracting App.
                  <br />
                  <br />
                  You received this email because of{" "}
                  <span className={classes.primaryColor}>
                    {invite.contractor || "the contractor"}’s
                  </span>{" "}
                  account on{" "}
                  <a
                    href={`https://${REACT_APP_CLIENT_WEBSITE}`}
                    target="_blank"
                    rel="noopener noreferrer"
                    className={classes.primaryColor}
                  >
                    www.rhapsody.la
                  </a>
                  .
                  <br />
                  If you don’t want to receive any more emails, you should
                  contact them directly.
                  <br />
                  <br />
                  Developed with 💛 by WID LLC in Los Angeles, California.
                  <br />
                  <a
                    target="_blank"
                    rel="noopener noreferrer"
                    href={"mailTo:contact@wid.la"}
                    className={classes.primaryColor}
                  >
                    contact@wid.la
                  </a>
                </Typography>
              </Grid>
            </Grid>
          </div>
        ) : (
          []
        )}
        <AnswerConfirmation
          contractor={(invite && invite.contractor) || "the contractor"}
          open={this.state.confirmMessageOpen}
          action={this.state.action}
          close={() =>
            this.setState({ confirmMessageOpen: false, action: undefined })
          }
          onConfirm={this.acknowledgeStudioSessionInvite.bind(this)}
        />
        <Dialog
          open={this.state.ErrorJSX}
          onClose={() => this.setState({ ErrorJSX: undefined })}
        >
          <DialogTitle>There are some issues...</DialogTitle>
          <DialogContent>{this.state.ErrorJSX}</DialogContent>
          <DialogActions>
            <Button
              onClick={() => this.setState({ ErrorJSX: undefined })}
              fullWidth
              variant="contained"
            >
              Fix
            </Button>
          </DialogActions>
        </Dialog>
        <Backdrop
          open={this.state.backdrop}
          style={{
            zIndex: 999,
            backdropFilter: "blur(10px)",
            WebkitBackdropFilter: "blur(10px)",
            color: "white",
            transition: "backdrop-filter .5s",
          }}
        >
          <div
            style={{
              textAlign: "center",
            }}
          >
            <CircularProgress />
            <Typography style={{ color: "white" }}>Loading</Typography>
          </div>
        </Backdrop>
        {confetti ? (
          <div style={{ zIndex: 1301, position: "fixed", top: 0, left: 0 }}>
            <Confetti
              recycle={false}
              gravity={0.2}
              tweenDuration={4000}
              numberOfPieces={200}
            />
          </div>
        ) : (
          []
        )}
        {selectedInteractor ? (
          <div
            style={{
              position: "fixed",
              bottom: 0,
              zIndex: 1000,
              width: "calc(100% - 40px)",
              padding: 20,
              background: selectedInteractor.color,
              color: "white",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              flexWrap: "wrap",
            }}
          >
            <div style={{ textAlign: "center" }}>
              <Typography color="inherit" display="block">
                You are about to {selectedInteractor.title}, please confirm
              </Typography>
              {selectedInteractor.id === 5 || selectedInteractor.id === 1 ? (
                <>
                  <Divider
                    style={{
                      opacity: 0.2,
                      background: "white",
                    }}
                  />
                  <Typography
                    variant="caption"
                    color="textSecondary"
                    display="block"
                    style={{ fontSize: 11, color: "white", maxWidth: 600 }}
                  >
                    <b>DISCLAIMER:</b> By accepting this call, you allow The
                    contractor: {invite.contractor} to use your personal data
                    (Address, SSN, EIN) on Rhapsody in order to process payroll
                    documents.
                  </Typography>
                </>
              ) : (
                []
              )}
            </div>
            {selectedInteractor.description ? (
              <>
                <div style={{ height: 0, flexBasis: "100%" }} />
                <Typography
                  color="inherit"
                  variant="caption"
                  style={{ opacity: 0.58 }}
                >
                  {selectedInteractor.description}
                </Typography>
              </>
            ) : (
              []
            )}
            <div style={{ height: 0, flexBasis: "100%", marginTop: 10 }} />
            <div
              style={{
                display: "flex",
                flex: 1,
                maxWidth: 600,
              }}
            >
              <Button
                variant="outlined"
                fullWidth
                style={{
                  flex: 1,
                  marginRight: 4,
                  color: "white",
                  borderColor: "white",
                  background: "rgba(255,255,255,0.1)",
                  textTransform: "none",
                }}
                onClick={() => this.setState({ selectedInteractor: undefined })}
              >
                Cancel
              </Button>
              <Button
                variant="contained"
                fullWidth
                style={{
                  flex: 1,
                  marginLeft: 4,
                  color: selectedInteractor.color,
                  background: "white",
                  textTransform: "none",
                }}
                onClick={() => this.onSubmit(selectedInteractor)}
              >
                {selectedInteractor.icon ? (
                  <i
                    class={selectedInteractor.icon}
                    style={{ paddingRight: 8 }}
                  ></i>
                ) : (
                  []
                )}{" "}
                {selectedInteractor.title}
              </Button>
            </div>
          </div>
        ) : (
          []
        )}
        {selectedInteractor ? (
          <div
            style={{
              position: "absolute",
              background: "rgba(0,0,0,0.6)",
              height: "100%",
              width: "100%",
              top: 0,
              zIndex: 999,
            }}
          />
        ) : (
          []
        )}
      </Page>
    );
  }
}
export default withStyles(styles)(App);
