import moment from "moment-timezone";

export function createIcs(invite) {
  const engagements = invite.engagements;
  const header = `BEGIN:VCALENDAR
VERSION:2.0
CALSCALE:GREGORIAN
PRODID:adamgibbons/ics
METHOD:PUBLISH
X-PUBLISHED-TTL:PT1H`;

  const footer = `END:VCALENDAR`;

  const content = [];

  for (const key in engagements) {
    if (engagements.hasOwnProperty(key)) {
      const engagement = engagements[key];
      if (!engagement.tzName) {
        throw Error(
          `The Engagement #${
            Number(key) + 1
          } doesn't have any timezone. The error has been automatically reported the Rhapsody Team.`
        );
      }

      const vTime = getVtimezoneFromMomentZone(engagement.tzName);

      if (vTime === -1) {
        throw Error(
          `The Engagement #${
            Number(key) + 1
          } has an unrecognized timezone. The error has been automatically reported the Rhapsody Team.`
        );
      }
      content.push(vTime);
      content.push(`BEGIN:VEVENT
UID:${uuidv4()}
SUMMARY:${`${engagement.name} - ${invite.projectName}`}
DTSTAMP:${moment().format("YYYYMMDDTHHmmss")}
DTSTART:${moment
        .tz(engagement.dateFromUTC, engagement.tzName)
        .format("YYYYMMDDTHHmmss")}
DTEND:${moment
        .tz(engagement.dateToUTC, engagement.tzName)
        .format("YYYYMMDDTHHmmss")}
DESCRIPTION:${`Invited by ${invite.contractor} - ${invite.contractorEmail} - ${invite.companyPhone} For role ${invite.role} in section ${invite.section}`}
URL:${window.location.href}
GEO:${Number(engagement.venue.latitude)};${Number(engagement.venue.longitude)}
LOCATION:${
        engagement.venue
          ? `${engagement.venue.address} ${engagement.venue.city}, ${engagement.venue.state} ${engagement.venue.zipcode}`
          : undefined
      }
CATEGORIES:Rhapsody
END:VEVENT
`);
    }
  }

  const event = [header, content.join(""), footer].join(`\r\n`);

  return formatIcs(75, event);
}

export function getVtimezoneFromMomentZone(tzName) {
  const MAX_OCCUR = 2;
  const zone = moment.tz.zone(tzName);
  if (!zone) return -1;
  const header = `BEGIN:VTIMEZONE\nTZID:${tzName}`;
  const footer = "END:VTIMEZONE";

  let zTZitems = "";
  for (let i = 0; i < MAX_OCCUR && i + 1 < zone.untils.length; i++) {
    const type = i % 2 === 0 ? "STANDARD" : "DAYLIGHT";
    const momDtStart = moment.tz(zone.untils[i], tzName);
    const momNext = moment.tz(zone.untils[i + 1], tzName);
    const item = `BEGIN:${type}
DTSTART:${momDtStart.format("YYYYMMDDTHHmmss")}
TZOFFSETFROM:${momDtStart.format("ZZ")}
TZOFFSETTO:${momNext.format("ZZ")}
TZNAME:${zone.abbrs[i]}
END:${type}\n`;
    zTZitems += item;
  }
  const result = `${header}\n${zTZitems}${footer}\n`;
  return result;
}

export function uuidv4() {
  return ([1e7] + -1e3 + -4e3 + -8e3 + -1e11).replace(/[018]/g, (c) =>
    (
      c ^
      (crypto.getRandomValues(new Uint8Array(1))[0] & (15 >> (c / 4)))
    ).toString(16)
  );
}

export function chunkString(str, length) {
  return str.match(new RegExp(".{1," + length + "}", "g"));
}

export function formatIcs(limit, string) {
  const result = [];
  var rnsplit = string.split("\r\n");
  var split = [];
  rnsplit.forEach((r) => split.push(...r.split("\n")));

  for (const key in split) {
    if (Object.hasOwnProperty.call(split, key)) {
      const line = split[key];
      if (line.length === 0) continue;
      if (line.length < limit) {
        result.push(line);
      } else {
        result.push(chunkString(line, limit).join("\r\n "));
      }
    }
  }

  return result.join("\r\n");
}
