import * as Sentry from "@sentry/react";
import { Integrations } from "@sentry/tracing";
import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import SockJS from "sockjs-client";
import App from "./App";
import "./index.css";
import { unregister } from "./serviceWorker";
import store from "./store";

window.SockJS = SockJS;

Sentry.init({
  dsn:
    "https://b7144031155b4aad89fd10faa3a07532@o1058683.ingest.sentry.io/6091715",
  integrations: [new Integrations.BrowserTracing()],

  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 1.0,
});

ReactDOM.render(
  <Provider store={store()}>
    <App />
  </Provider>,
  document.getElementById("root")
);
unregister();
